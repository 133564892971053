<style lang="scss" scoped>
.banner {
	display: flex;
	justify-content: center;
	margin-top: 0.2rem;
}
img {
	width: 7.3rem;
	height: 4.05rem;
}
</style>

<template>
	<div class="banner">
		<img v-if="url" :src="url" alt="banner图" />
	</div>
</template>

<script>
export default {
	name: 'banner',
	props: {
		url: {
			type: String,
			required: false,
			default: '',
		},
	},
};
</script>
