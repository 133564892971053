// 签名相关方法
import { http_updateSignatureBillId } from '@/request/notification.js';

export default {
	data() {
		return {};
	},
	methods: {
		/**
		 * 更新签名信息中的订单id
		 * @param {String} billId 订单id
		 */
		mixin_updateSignaturesBillId(billId) {
			// 地址中，必须有 signatureIds 字段
			let query = this.$route.query;
			if (query && Object.hasOwnProperty.call(query, 'signatureIds')) {
				http_updateSignatureBillId(query.signatureIds, billId);
			}
		},
	},
};
