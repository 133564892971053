// 告知书相关接口
import { get, post, postUrl } from './http';

// 根据id获取告知书
export function http_getNotificationById(id) {
	return get(`/insurance/api/notification/getNotificationById/${id}`);
}

// 保存签名信息
export function http_saveSignature(data) {
	return post(`/insurance/api/notification/saveSignature`, data);
}

// 保存签名文件
export function http_uploadFile(formData) {
	return post(`/insurance/api/notification/signatureFileUpload`, formData);
}

// 更新签名记录的订单Id
export function http_updateSignatureBillId(signatureIds, billId) {
	return get(`/insurance/api/notification/updateBillId/${signatureIds}/${billId}`);
}
